export const reprintCardApprovedConstants = {
  GET_REPRINT_CARD_APPROVED_SUCCESS: "GET_REPRINT_CARD_APPROVED_SUCCESS",
  GET_REPRINT_CARD_APPROVED_FAIL: "GET_REPRINT_CARD_APPROVED_FAIL",
  LOADING_PRINT_APPROVED: "LOADING_PRINT_APPROVED",
  LOADING_UPDATED: "LOADING_UPDATED",
  LOADING: "LOADING",
  CLEAR_DATA: "CLEAR_DATA",
  CARD_REPRINT_PRINT_SUCCESS: "CARD_REPRINT_PRINT_SUCCESS",
  CARD_REPRINT_PRINT_FAIL: "CARD_REPRINT_PRINT_FAIL",
  CARD_REPRINT_PRINTED_SUCCESS: "CARD_REPRINT_PRINTED_SUCCESS",
  CARD_REPRINT_PRINTED_FAIL: "CARD_REPRINT_PRINTED_FAIL",
};

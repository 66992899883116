//constants for the bank
export const organizationSetupConstants = {
  LOADING_ORGANIZATION_SETUP: "LOADING_ORGANIZATION_SETUP",
  GET_ORGANIZATION_SETUP_SUCCESS: "GET_ORGANIZATION_SETUP_SUCCESS",
  GET_ORGANIZATION_SETUP_FAIL: "GET_ORGANIZATION_SETUP_FAIL",
  CREATE_ORGANIZATION_SETUP_SUCCESS: "CREATE_ORGANIZATION_SETUP_SUCCESS",
  CREATE_ORGANIZATION_SETUP_FAIL: "CREATE_ORGANIZATION_SETUP_FAIL",
  ORGANIZATION_SETUP_EDIT: "ORGANIZATION_SETUP_EDIT",
  UPDATE_ORGANIZATION_SETUP_SUCCESS: "UPDATE_ORGANIZATION_SETUP_SUCCESS",
  UPDATE_ORGANIZATION_SETUP_FAIL: "UPDATE_ORGANIZATION_SETUP_FAIL",
  LOADING_UPDATED: "LOADING_UPDATED",
  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
  // organization
  LOADING_ORGANIZATION: "LOADING_ORGANIZATION",
  GET_ORGANIZATION_SUCCESS: "GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAIL: "GET_ORGANIZATION_FAIL",
};

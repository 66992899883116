export const reportConstants = {
  LOADING_REPORT: "LOADING_REPORT",

  GET_NEW_CARD_REPORT_SUCCESS: "GET_NEW_CARD_REPORT_SUCCESS",
  GET_NEW_CARD_REPORT_FAIL: "GET_NEW_CARD_REPORT_FAIL",
  GET_ALL_NEW_CARD_REPORT_SUCCESS: "GET_ALL_NEW_CARD_REPORT_SUCCESS",
  GET_ALL_NEW_CARD_REPORT_FAIL: "GET_ALL_NEW_CARD_REPORT_FAIL",

  GET_RENEW_REPORT_SUCCESS: "GET_RENEW_REPORT_SUCCESS",
  GET_RENEW_REPORT_FAIL: "GET_RENEW_REPORT_FAIL",
  GET_ALL_RENEW_REPORT_SUCCESS: "GET_ALL_RENEW_REPORT_SUCCESS",
  GET_ALL_RENEW_REPORT_FAIL: "GET_ALL_RENEW_REPORT_FAIL",

  GET_REPRINT_REPORT_SUCCESS: "GET_REPRINT_REPORT_SUCCESS",
  GET_REPRINT_REPORT_FAIL: "GET_REPRINT_REPORT_FAIL",
  GET_ALL_REPRINT_REPORT_SUCCESS: "GET_ALL_REPRINT_REPORT_SUCCESS",
  GET_ALL_REPRINT_REPORT_FAIL: "GET_ALL_REPRINT_REPORT_FAIL",

  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
};

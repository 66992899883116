export const reprintCardConstants = {
  GET_REPRINT_CARD_SUCCESS: "GET_REPRINT_CARD_SUCCESS",
  GET_REPRINT_CARD_FAIL: "GET_REPRINT_CARD_FAIL",
  LOADING_PRINT: "LOADING_PRINT",
  LOADING_UPDATED: "LOADING_UPDATED",
  LOADING: "LOADING",
  CLEAR_DATA: "CLEAR_DATA",
  APPROVE_CARD_SUCCESS:"APPROVE_CARD_SUCCESS",
  APPROVE_CARD_FAIL:"APPROVE_CARD_FAIL",
};

export const roleConstants = {
  LOADING_ROLE: "LOADING_ROLE",
  LOADING_UPDATE_ROLE: "LOADING_UPDATE_ROLE",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAIL: "GET_ROLE_FAIL",
  GET_ALL_ROLE_SUCCESS: "GET_ALL_ROLE_SUCCESS",
  GET_ALL_ROLE_FAIL: "GET_ALL_ROLE_FAIL",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAIL: "CREATE_ROLE_FAIL",
  GET_EDIT_ROLE: "GET_EDIT_ROLE",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAIL: "UPDATE_ROLE_FAIL",

  GET_ALL_PERMISSION_SUCCESS: "GET_ALL_PERMISSION_SUCCESS",
  GET_ALL_PERMISSION_FAIL: "GET_ALL_PERMISSION_FAIL",
  LOADING_PERMISSIONS: "LOADING_PERMISSIONS",
  CLEAR_DATA: "CLEAR_DATA",
};

//constants for the bank
export const officeConstants = {
  LOADING_OFFICE: "LOADING_OFFICE",
  GET_OFFICE_SUCCESS: "GET_OFFICE_SUCCESS",
  GET_OFFICE_FAIL: "GET_OFFICE_FAIL",
  GET_ALL_OFFICE_SUCCESS: "GET_ALL_OFFICE_SUCCESS ",
  GET_ALL_OFFICE_FAIL: "GET_ALL_OFFICE_FAIL",
  CREATE_OFFICE_SUCCESS: "CREATE_OFFICE_SUCCESS",
  CREATE_OFFICE_FAIL: "CREATE_OFFICE_FAIL",
  EDIT_OFFICE: "EDIT_OFFICE",
  UPDATE_OFFICE_SUCCESS: "UPDATE_OFFICE_SUCCESS",
  UPDATE_OFFICE_FAIL: "UPDATE_OFFICE_FAIL",
  LOADING_UPDATED: "LOADING_UPDATED",
  CLEAR_OFFICE: "CLEAR_OFFICE",
};

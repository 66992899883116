//constants for the bank
export const userConstants = {
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAIL: "GET_USER_FAIL",
  GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS ",
  GET_ALL_USER_FAIL: "GET_ALL_USER_FAIL",
  GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
  GET_USER_INFO_FAIL: "GET_USER_INFO_FAIL",
  USER_DELETED_SUCCESS: "USER_DELETED_SUCCESS",
  USER_DELETED_FAIL: "USER_DELETED_FAIL",
  USER_EDIT_SUCCESS: "USER_EDIT_SUCCESS",
  PHOTO_DELETED_SUCCESS: "PHOTO_DELETED_SUCCESS",
  PHOTO_DELETED_FAIL: "PHOTO_DELETED_FAIL",
  LOADING_USER: "LOADING_USER",
  CLEAR_USER: "CLEAR_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",
  LOADING_UPDATED: "LOADING_UPDATED",
  CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
  CHANGE_USER_PASSWORD_FAIL: "CHANGE_USER_PASSWORD_FAIL",
};

export const verifyCardConstants = {
  GET_VERIFY_CARD_SUCCESS: "GET_VERIFY_CARD_SUCCESS",
  GET_VERIFY_CARD_FAIL: "GET_VERIFY_CARD_FAIL",
  VERIFY_CARD_SUCCESS: "VERIFY_CARD_SUCCESS",
  VERIFY_CARD_FAIL: "VERIFY_CARD_FAIL",
  EDIT_VERIFY_CARD: "EDIT_VERIFY_CARD",
  UPDATE_VERIFY_CARD_SUCCESS: "UPDATE_VERIFY_CARD_SUCCESS",
  UPDATE_VERIFY_CARD_FAIL: "UPDATE_VERIFY_CARD_FAIL",
  LOADING_VERIFY_CARD: "LOADING_VERIFY_CARD",
  LOADING_UPDATED: "LOADING_UPDATED",
  APPROVE_VERIFY_CARD_SUCCESS: "APPROVE_VERIFY_CARD_SUCCESS",
  APPROVE_VERIFY_CARD_FAIL: "APPROVE_VERIFY_CARD_FAIL",
  APPROVE_VERIFY_CARD_FAIL: "APPROVE_VERIFY_CARD_FAIL",
  CLEAR_DATA: "CLEAR_DATA",
};

export const cardConstants = {
  CREATE_CARD_SUCCESS: "CREATE_CARD_SUCCESS",
  CREATE_CARD_FAIL: "CREATE_CARD_FAIL",
  GET_CARD_SUCCESS: "GET_CARD_SUCCESS",
  GET_CARD_FAIL: "GET_CARD_FAIL",
  CARD_DELETED_SUCCESS: "CARD_DELETED_SUCCESS",
  CARD_DELETED_FAIL: "CARD_DELETED_FAIL",
  EDIT_CARD_SUCCESS: "EDIT_CARD_SUCCESS",
  CLOSE_EDIT_CARD_SUCCESS: "CLOSE_EDIT_CARD_SUCCESS",
  EDIT_CARD_FAIL: "EDIT_CARD_FAIL",
  CARD_UPDATE_SUCCESS: "CARD_UPDATE_SUCCESS",
  CARD_UPDATE_FAIL: "CARD_UPDATE_FAIL",
  LOADING_CARD: "LOADING_CARD",
  LOADING_CREATE: "LOADING_CREATE",
  LOADING_UPDATED: "LOADING_UPDATED",
  LOADING_REJECT: "LOADING_REJECT",
  PASS_REJECTED_SUCCESS: "PASS_REJECTED_SUCCESS",
  PASS_REJECTED_FAIL: "PASS_REJECTED_FAIL",
  CLEAR_DATA: "CLEAR_DATA",
  CLEAR_EDIT_CARD_SUCCESS: "CLEAR_EDIT_CARD_SUCCESS",
  
};

export const renewCardConstants = {
  CREATE_CARD_SUCCESS: "CREATE_CARD_RENEW_SUCCESS",
  CREATE_CARD_RENEW_FAIL: "CREATE_CARD_RENEW_FAIL",
  GET_CARD_RENEW_SUCCESS: "GET_CARD_RENEW_SUCCESS",
  GET_CARD_RENEW_FAIL: "GET_CARD_RENEW_FAIL",
  GET_ALL_CARD_RENEW_SUCCESS: "GET_ALL_CARD_RENEW_SUCCESS",
  GET_ALL_CARD_RENEW_FAIL: "GET_ALL_CARD_RENEW_FAIL",
  LOADING_CARD_RENEW: "LOADING_CARD_RENEW",
  EDIT_RENEW_CARD: "EDIT_RENEW_CARD",
  CLEAR_DATA: "CLEAR_DATA",
};
